import React, { useEffect, useState, SetStateAction, Dispatch, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  QuestionCategoryListApi,
  CommonMasterListOutputResponse,
  EnqueteInfoOutputResponse,
  EnquetePointSettingOutputResponse,
  QuestionInfoApi,
  QuestionInfoOutputResponse,
  QuestionListApi,
  QuestionListOutputResponse,
  QuestionCategoryListOutputResponse,
  EnqueteQuestionSettingOutputResponse,
  EnqueteQuestionsDataResponse,
  AllClientResponse,
  QuestionCategoryForEachHierarchListOutputResponse,
  QuestionCategoryForEachHierarchyListApi,
  IdNameOutputResponse,
} from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
import { Title } from '../../atoms/Title';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { QuestionListModalTable } from '../Table/QuestionListModalTable';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';
import { Checkbox } from '../../atoms/Checkbox';
import scss from '../../../scss/organisms/Modal/QuestionListModale.module.scss';

export interface Props {
  data?: EnqueteInfoOutputResponse;
  setData: DispatchSetState<EnqueteInfoOutputResponse>;
  showFlg: boolean;
  setShowFlg: Dispatch<SetStateAction<boolean>>;
  enqueteType: number;
  originQuestion:
    | {
        enqueteQuestionsId: number;
        questionId: number;
        mailContent: string | undefined;
        deleteFlg: boolean;
        questionCategoryId: number | undefined;
      }
    | undefined;
  setOriginQuestion: DispatchSetState<
    | {
        enqueteQuestionsId: number;
        questionId: number;
        mailContent: string | undefined;
        deleteFlg: boolean;
        questionCategoryId: number | undefined;
      }
    | undefined
  >;
  setIsQuestionConfirmModal: DispatchSetState<boolean>;
  deleteQuestionList: EnqueteQuestionsDataResponse[];
  setDeleteQuestionList: React.Dispatch<React.SetStateAction<EnqueteQuestionsDataResponse[]>>;
  clientList: AllClientResponse[];
}

export const QuestionListModal: React.VFC<Props> = ({
  data,
  setData,
  showFlg,
  enqueteType,
  setShowFlg,
  originQuestion,
  setOriginQuestion,
  setIsQuestionConfirmModal,
  deleteQuestionList,
  setDeleteQuestionList,
  clientList,
}) => {
  // レスポンスデータ
  const [questionListdata, setQuestionListData] = useState<QuestionListOutputResponse[]>([]);

  // 検索パラメータ保持用
  const [searchParams, setSearchParms] = useState<{
    questionId?: number;
    question?: string;
    category?: number;
    client?: number;
  }>({});

  //  ページ関連変数
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listPerPage, setListPerPage] = useState<QuestionListOutputResponse[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const MAX_RECORD_PER_PAGE = 20;

  // 【会員属性アンケート編集】導線の表示・非表示フィルタリングフラグ
  const [attributeHideFlg, setAttributeHideFlg] = useState<boolean>(false);

  // レスポンスデータ複製（会員属性PTで使用）
  const [copyData, setCopyData] = useState<QuestionListOutputResponse[]>([]);
  //  カテゴリリスト
  const [categoryList, setCategoryList] = useState<CommonMasterListOutputResponse[]>();
  const [categories, setCategories] = useState<Partial<QuestionCategoryForEachHierarchListOutputResponse>>({});
  // 設問選択判定フラグ (設問を選択したかの判定をする)
  const [selectedFlg, setSelectedFlg] = useState<boolean>(false);

  const questionListApi = new QuestionListApi();
  const questionInfoApi = new QuestionInfoApi();
  const questionCategoryListApi = new QuestionCategoryListApi();
  const questionCategoryForEachHierarchyListApi = new QuestionCategoryForEachHierarchyListApi();

  const [selectedQuestionIdList, setSelectedQuestionIdList] = useState<number[]>([]);

  const [levelList, setLevelList] = useState<CommonMasterListOutputResponse[]>([]);
  const [questionLevels, setQuestionLevels] = useState<number[]>([]);

  const noneQuestionCategoryData: IdNameOutputResponse = {
    id: -1,
    name: 'カテゴリ無し',
  };

  const customStyles2 = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        height: 58,
      }),
    }),
    []
  );

  useEffect(() => {
    if (!showFlg || !enqueteType) return;

    const researchTypeId = data?.enqueteType === ENQUETE_TYPE.SELECT ? data.researchTypeId : undefined;

    questionListApi
      .questionList(undefined, undefined, undefined, undefined, enqueteType, undefined, researchTypeId)
      .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
        setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
        setCopyData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
      });
  }, [showFlg, enqueteType]);

  useEffect(() => {
    if (!showFlg || enqueteType !== ENQUETE_TYPE.SELECT) return;
    questionCategoryListApi
      .questionCategoryList(
        data?.clients?.map((c) => c.id),
        true
      )
      .then((res: AxiosResponse<QuestionCategoryListOutputResponse[]>) => {
        setCategoryList(res.data);
      });
  }, [showFlg, enqueteType, data?.clients]);

  useEffect(() => {
    if (!showFlg || enqueteType !== ENQUETE_TYPE.SELECT) return;
    questionCategoryForEachHierarchyListApi
      .questionCategoryForEachHierarchyList()
      .then((res: AxiosResponse<QuestionCategoryForEachHierarchListOutputResponse>) => {
        if (res.data?.companyMediumQuestionCategories !== undefined)
          res.data.companyMediumQuestionCategories.push(noneQuestionCategoryData);
        setCategories(res.data);
      });
  }, [showFlg, enqueteType, data?.clients]);

  useEffect(() => {
    setListPerPage(questionListdata?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(questionListdata.length) / MAX_RECORD_PER_PAGE));
  }, [questionListdata, currentPage]);

  useEffect(() => {
    const list = [1, 2, 3, 4, 5].map((it) => {
      return { id: it, name: `レベル${it.toString()}` } as CommonMasterListOutputResponse;
    });

    setLevelList(list);
  }, []);

  // 検索ボタンイベント
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    const selectedCategoryList: number[] | undefined = [];
    if (searchParams.category) selectedCategoryList?.push(searchParams.category);

    let level: number[] | undefined;
    if (enqueteType === ENQUETE_TYPE.SELECT) {
      level = questionLevels.length > 0 ? questionLevels : undefined;
    }

    const researchTypeId = data?.enqueteType === ENQUETE_TYPE.SELECT ? data.researchTypeId : undefined;

    questionListApi
      .questionList(
        searchParams.questionId,
        searchParams.question,
        selectedCategoryList,
        searchParams.client,
        enqueteType,
        level,
        researchTypeId
      )
      .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
        setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
        setCopyData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
        setCurrentPage(1);
      });
  };

  // 各種Text系変更イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newData: QuestionListOutputResponse[] = Object.assign([], questionListdata);
    switch (event.target.id) {
      case 'questionId':
        setSearchParms({
          ...searchParams,
          questionId: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'question':
        setSearchParms({ ...searchParams, question: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'showFlg':
        if (event.target.checked && attributeHideFlg === true) {
          setQuestionListData(copyData);
        } else if (event.target.checked && attributeHideFlg === false) {
          newData = questionListdata?.filter((row) => row.displayFlg === true);
          setQuestionListData(newData);
        } else if (!event.target.checked && attributeHideFlg === true) {
          newData = questionListdata?.filter((row) => row.displayFlg === false);
          setQuestionListData(newData);
        } else {
          setQuestionListData(copyData);
        }
        setCurrentPage(1);
        break;
      case 'hideFlg':
        if (event.target.checked && showFlg === true) {
          setQuestionListData(copyData);
        } else if (event.target.checked && showFlg === false) {
          newData = questionListdata?.filter((row) => row.displayFlg === false);
          setQuestionListData(newData);
        } else if (!event.target.checked && showFlg === true) {
          newData = questionListdata?.filter((row) => row.displayFlg === true);
          setQuestionListData(newData);
        } else {
          setQuestionListData(copyData);
        }
        setCurrentPage(1);
        break;
      default:
        break;
    }
  };
  // 保存ボタンイベント
  const handleOnKeep = async (e: any) => {
    if (selectedQuestionIdList.length === 0) {
      setSelectedFlg(true);
      return;
    }

    const addEnqueteQuestionList: EnqueteQuestionSettingOutputResponse[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const selected of selectedQuestionIdList) {
      // 選択された入れ替え元の設問を削除リストに追加
      if (originQuestion && originQuestion.enqueteQuestionsId !== 0) {
        setDeleteQuestionList([
          ...deleteQuestionList,
          {
            id: originQuestion.enqueteQuestionsId,
            questionId: originQuestion.questionId,
            mailContent: originQuestion.mailContent,
            deleteFlg: true,
            questionCategoryId: originQuestion.questionCategoryId,
          },
        ]);
      }
      await questionInfoApi.questionInfo(selected).then((res: AxiosResponse<QuestionInfoOutputResponse>) => {
        // 選択された設問の情報を取得（一覧から取得）
        const selectedQuestion: QuestionListOutputResponse | undefined = questionListdata.find(
          (_) => _.questionId === selected
        );

        if (!selectedQuestion || !data) return;

        // 選択された設問の選択肢を取得---------------------------------------------------
        const choiceList: any[] = [];
        for (let index = 0; index < res.data.selections.length; index += 1) {
          choiceList.push({
            selectionId: res.data.selections[index].selectionId,
            content: res.data.selections[index].content,
          });
        }
        // ------------------------------------------------------------------------------

        // 積上式のみ実施------------------------------------------------------------------
        const allocationPointsList: EnquetePointSettingOutputResponse[] = Object.assign([], data?.pointSettings);

        // 積上配点情報を取得
        for (let index = 0; index < res.data.selections.length; index += 1) {
          allocationPointsList.push({
            questionId: selectedQuestion.questionId,
            pointSettingId: index + 1,
            selectionId: index + 1,
            selectionContent: res.data.selections[index].content,
            point: res.data.selections[index].point,
          });
        }

        const questionCategoryId =
          res.data?.questionCategorySmallId || res.data?.questionCategoryMediumId || res.data?.questionCategoryLargeId;

        const questionCategoryName = categoryList?.find(
          (category: CommonMasterListOutputResponse) => category.id === 1
        )?.name;

        if (enqueteType === ENQUETE_TYPE.PILEUP) {
          // 上書き対象の積上配点情報を削除
          for (let index = 0; index < allocationPointsList.length; index += 1) {
            if (originQuestion?.questionId === data?.pointSettings[index]?.questionId) {
              delete allocationPointsList[index];
            }
          }
        }
        // 新たに追加する設問のリストを作成
        addEnqueteQuestionList.push({
          questionId: selectedQuestion?.questionId,
          enqueteQuestionsId: 0,
          answerMethod: res.data.questionAnswerMethod,
          content: selectedQuestion?.questionContent,
          mailContent: undefined,
          pointSettingDisableFlg: res.data.selectionPointDisableFlg,
          questionCategoryId: questionCategoryId || undefined,
          questionCategoryName: res.data.questionCategoryName || undefined,
          selections: choiceList,
          level: res.data.level,
        });

        setData((prevState) => {
          return {
            ...prevState,
            pointSettings: allocationPointsList.filter(Boolean),
            branches: data?.branches,
          };
        });
      });
    }
    // 設問の追加であればリストの一番後ろに入れる
    // 入れ替えであれば入れ替え元の設問があった場所に入れる
    if (!originQuestion) {
      setData((prevState) => {
        return {
          ...prevState,
          enqueteQuestionsList: prevState.enqueteQuestionsList.concat(addEnqueteQuestionList),
        };
      });
    } else {
      let newEnqueteQuestionList: EnqueteQuestionSettingOutputResponse[] = [];
      data?.enqueteQuestionsList.forEach((question) => {
        if (question.questionId === originQuestion?.questionId) {
          newEnqueteQuestionList = [...newEnqueteQuestionList, ...addEnqueteQuestionList];
        } else {
          newEnqueteQuestionList = [...newEnqueteQuestionList, question];
        }
      });
      setData((prevState) => {
        return {
          ...prevState,
          enqueteQuestionsList: newEnqueteQuestionList,
        };
      });
    }
    onHide();
  };

  // カテゴリセレクトボックスchangeイベント
  const onChangeSelectCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchParms({
      ...searchParams,
      category: Number(event.target.value) === 0 ? undefined : Number(event.target.value),
    });
  };

  // キャンセルまたはモーダルを閉じた場合のイベント
  const onHide = () => {
    setShowFlg(false);
    setOriginQuestion(undefined);
    setSelectedQuestionIdList([]);
  };

  const customStyles = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        height: 58,
      }),
    }),
    []
  );

  // 【事前アンケート編集】
  // 【積上式アンケート編集】
  // 【その他アンケート編集】
  // 【アンケートモニターアンケート編集】
  // からの遷移の場合のPageContents
  const pageContents1 = (
    <>
      <Card className="mb-4 p-1">
        <Table className="table-borderless" style={{ width: '100%', height: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '20%' }}>
                <div className="form-floating mt-3" style={{ marginLeft: 9 }}>
                  <input
                    data-testid="search_questionId"
                    type="number"
                    className="form-control"
                    id="questionId"
                    placeholder="入力してください。"
                    value={searchParams.questionId === undefined ? '' : searchParams.questionId}
                    onChange={onChangeText}
                  />
                  <label>設問ID</label>
                </div>
              </td>
              <td>
                <div className="form-floating mt-3">
                  <input
                    data-testid="search_question"
                    type="text"
                    className="form-control"
                    id="question"
                    placeholder="入力してください。"
                    value={searchParams.question === undefined ? '' : searchParams.question}
                    onChange={onChangeText}
                  />
                  <label htmlFor="contractShopName">設問</label>
                </div>
              </td>
              {enqueteType === ENQUETE_TYPE.PILEUP && (
                <td>
                  <div className="form-floating mt-3 me-2">
                    <Select
                      id="client"
                      styles={customStyles}
                      onChange={(current) => setSearchParms({ ...searchParams, client: current?.clientId })}
                      closeMenuOnSelect
                      getOptionLabel={(option: any) => option.clientName}
                      getOptionValue={(option: any) => option.clientId?.toString()}
                      options={clientList}
                      placeholder="使用クライアント"
                    />
                  </div>
                </td>
              )}
              <td style={{ textAlign: 'center' }}>
                <p style={{ width: '98%', height: '85%' }}>
                  <Button data-testid="search" type="submit" className="mt-3" style={{ width: '98%', height: '100%' }}>
                    検索
                  </Button>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </>
  );

  // 【選択式アンケート編集】
  // からの遷移の場合のPageContents
  const pageContents2 = (
    <>
      <Card className="mb-4 mt-4">
        <Table className="table-borderless" style={{ width: '100%', height: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '25%' }}>
                <div className="form-floating mt-3" style={{ marginLeft: 9 }}>
                  <input
                    data-testid="search_questionId"
                    type="number"
                    className="form-control"
                    id="questionId"
                    placeholder="入力してください。"
                    value={searchParams.questionId === undefined ? '' : searchParams.questionId}
                    onChange={onChangeText}
                  />
                  <label>設問ID</label>
                </div>
              </td>
              <td style={{ width: '25%' }}>
                <div className="mt-3 form-floating">
                  <Form.Select
                    data-testid="search_category"
                    defaultValue=""
                    className="form-select"
                    onChange={onChangeSelectCategory}
                  >
                    <option value="">選択してください</option>
                    {categories?.companyMediumQuestionCategoryMap &&
                      categories?.companyMediumQuestionCategoryMap[
                        `${data?.researchTypeId ? `${data.researchTypeId}` : ''}`
                      ]?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </Form.Select>
                  <label htmlFor="floatingSelect">カテゴリ</label>
                </div>
              </td>
              <td>
                <div className="form-floating mt-3">
                  <input
                    data-testid="search_question"
                    type="text"
                    className="form-control"
                    id="question"
                    placeholder="入力してください。"
                    value={searchParams.question === undefined ? '' : searchParams.question}
                    onChange={onChangeText}
                  />
                  <label htmlFor="contractShopName">設問</label>
                </div>
              </td>
              <td style={{ textAlign: 'center' }}>
                <p style={{ width: '95%', height: '80%', marginRight: 7 }}>
                  <Button data-testid="search" type="submit" className="mt-3" style={{ width: '95%', height: '100%' }}>
                    検索
                  </Button>
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '25%' }}>
                <div className="form-floating" style={{ marginLeft: 9 }}>
                  <Select
                    isMulti
                    options={levelList}
                    styles={customStyles2}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => String(id)}
                    closeMenuOnSelect={false}
                    placeholder="設問レベル"
                    className={scss.question_level_index}
                    onChange={(v) => {
                      setQuestionLevels(v.map((it) => it.id));
                    }}
                  />
                </div>
              </td>
              <td style={{ width: '25%' }}>
                <div className="form-floating me-2">
                  <Select
                    id="client"
                    styles={customStyles2}
                    onChange={(current) => setSearchParms({ ...searchParams, client: current?.clientId })}
                    closeMenuOnSelect
                    getOptionLabel={(option: any) => option.clientName}
                    getOptionValue={(option: any) => option.clientId?.toString()}
                    options={clientList}
                    placeholder="使用クライアント"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </>
  );

  return (
    <>
      <Modal size="xl" show={showFlg} onHide={onHide} scrollable centered>
        <Modal.Header closeButton>
          <Title className="mb-0">設問一覧</Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end mb-2">
            <div>
              <Button className="me-2" variant="link" onClick={onHide}>
                <p>キャンセル</p>
              </Button>
            </div>
            <div>
              <Button variant="primary" onClick={handleOnKeep}>
                保存
              </Button>
            </div>
          </div>
          {selectedFlg && (
            <Alert variant="danger" style={{ marginTop: 10 }}>
              設問が選択されていません。
            </Alert>
          )}
          <Form onSubmit={handleOnSubmit}>
            {enqueteType === ENQUETE_TYPE.BEFOREHAND && pageContents1}
            {enqueteType === ENQUETE_TYPE.SELECT && pageContents2}
            {enqueteType === ENQUETE_TYPE.PILEUP && pageContents1}
            {enqueteType === ENQUETE_TYPE.OTHER && pageContents1}
            {enqueteType === ENQUETE_TYPE.MEMBERSHIP && pageContents1}
            {enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR && pageContents1}

            {(enqueteType === ENQUETE_TYPE.SELECT ||
              enqueteType === ENQUETE_TYPE.PILEUP ||
              enqueteType === ENQUETE_TYPE.BEFOREHAND ||
              enqueteType === ENQUETE_TYPE.MEMBERSHIP) && (
              <div className="d-flex justify-content-end mb-4">
                <Button
                  variant="link"
                  className="text-secondary"
                  onClick={() => {
                    setShowFlg(false);
                    setIsQuestionConfirmModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                  追加
                </Button>
              </div>
            )}

            <PaginationWithEllipsis
              data-testid="page"
              currentPage={currentPage}
              totalPage={totalPage}
              handleClick={(page) => {
                if (!page || page > totalPage) return;
                setListPerPage(questionListdata.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                setCurrentPage(page);
              }}
            />
            <QuestionListModalTable
              data={data?.enqueteQuestionsList}
              listPerPage={listPerPage}
              enqueteType={enqueteType}
              setSelectedQuestionIdList={setSelectedQuestionIdList}
              selectedQuestionIdList={selectedQuestionIdList}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
