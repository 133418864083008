import React from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { BillingDestinationMailOutputResponse } from '../../../api-client';

export interface Props {
  onChangeAddress: (i: number, v: string) => void;
  onChangeAddressType: (i: number, v: number) => void;
  onChangeAddressStatus: (i: number, v: boolean) => void;
  onChangeAddressDisableFlg: (i: number, v: boolean) => void;
  mailList: BillingDestinationMailOutputResponse[];
}
const BILLING_MAIL_TYPE = [
  { id: 0, name: 'TO' },
  { id: 1, name: 'CC' },
];

export const BillingDestinationMailTable: React.VFC<Props> = ({
  onChangeAddress,
  onChangeAddressType,
  onChangeAddressStatus,
  onChangeAddressDisableFlg,
  mailList,
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>TO/CC</th>
            <th>アドレス</th>
            <th>バウンスフラグ</th>
            <th>バウンス日時</th>
            <th>バウンス理由</th>
            <th>削除フラグ</th>
          </tr>
        </thead>
        <tbody>
          {mailList.map((mail, index) => {
            return (
              <tr>
                <td>
                  <Form.Select
                    value={mail.mailTypeCode}
                    onChange={(e) => onChangeAddressType(index, Number(e.target.value))}
                  >
                    {BILLING_MAIL_TYPE.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <Form.Control
                    type="email"
                    value={mail.mailAddress}
                    onChange={(e) => onChangeAddress(index, e.target.value)}
                  />
                </td>
                <td>
                  <Form.Check.Input
                    checked={mail.bounceMailFlg}
                    type="checkbox"
                    onChange={(e) => onChangeAddressStatus(index, e.target.checked)}
                  />
                </td>
                <td>
                  <Form.Control readOnly value={mail.bounceMailFlgUpdateAt ?? ''} />
                </td>
                <td>
                  <Form.Control readOnly value={mail.bounceMailReason ?? ''} />
                </td>
                <td>
                  <Form.Check.Input
                    checked={mail.disableFlg}
                    type="checkbox"
                    onChange={(e) => onChangeAddressDisableFlg(index, e.target.checked)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
