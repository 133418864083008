export const Url = {
  TOP: '/',
  MY: '/my',
  LOGIN: '/login',
  LOGOUT: '/logout',
  COMMON_ERROR: '/error',
  MAINTENANCE: '/maintenance',
  REDIRECT: '/redirect',
  IMAGE_PREVIEW: '/image-preview',
  PASSWORD_EDIT: '/password-edit',

  KEISAI: {
    TOP: '/keisai/top',
    SQL_TMP: '/keisai/sqltemplate',
    SQL_TMP_INFO: '/keisai/sqltemplate-info/id',
    SQL_TMP_UPDATE: '/keisai/sqltemplate-update/id',
    SQL_TMP_UPDATE_CONFIRM: '/keisai/sqltemplate-update-confirm',
    SQL_TMP_CREATE: '/keisai/sqltemplate-create',
    SQL_TMP_CREATE_CONFIRM: '/keisai/sqltemplate-create-confirm',
    SQL_TMP_COMPLETION: '/keisai/sqltemplate-completion',
    SQL_TMP_DELETE_CONFIRM: '/keisai/sqltemplate-delete-confirm',
    SQL_QUERY: '/keisai/sqlquery',
    SQL_QUERY_INFO: '/keisai/sqlquery-info/id',
    SQL_QUERY_DELETE_CONFIRM: '/keisai/sqlquery-delete-confirm',
    SQL_QUERY_COMPLETION: '/keisai/sqlquery-completion',
    SQL_QUERY_UPDATE: '/keisai/sqlquery-update/id',
    SQL_QUERY_UPDATE_CONFIRM: '/keisai/sqlquery-update-confirm',
    SQL_QUERY_CREATE: '/keisai/sqlquery-create',
    SQL_QUERY_CREATE_CONFIRM: '/keisai/sqlquery-create-confirm',
    ABOUT_OPENING: '/keisai/about-opening',
    APPLICATION: '/keisai/application',
    APPLICATION_MODIFY: '/keisai/application-modify',
    POINT_EXCHANGE: '/keisai/point-exchange-site',
    API_CLIENT: '/keisai/api-client',
    API_CLIENT_MODIFY: '/keisai/api-client-modify/id',
    CLIENT_OF_REQUIRED_NUMBER: '/keisai/client-of-required-number',
    CLIENT_OF_REQUIRED_NUMBER_TARGET: '/keisai/client-of-required-number-target',
    CONTRACT_SHOP: '/keisai/contract-shop',
    CONTRACT_SHOP_CONFIRM: '/keisai/contract-shop-modify',
    SHOP: '/keisai/shop',
    SHOP_CONFIRM: '/keisai/shop-modify',
    FLOATING_REWARD: '/keisai/floating-reward',
    FLOATING_REWARD_CONFIRM: '/keisai/floating-reward-confirm',
    FLOATING_REWARD_UPLOAD_CONFIRM: '/keisai/floating-reward-upload-confirm',
    QUESTION: '/keisai/question',
    BRAND: '/keisai/brand',
    BRAND_DETAIL: '/keisai/brand-detail',
    BRAND_MODIFY: '/keisai/brand-modify',
    BRAND_MODIFY_CONFIRM: '/keisai/brand-modify-confirm',
    BRAND_RICH: '/keisai/brand-rich',
    BRAND_OEM: '/keisai/brand-oem',
    BRAND_OEM_CONFIRM: '/keisai/brand-oem-confirm',
    BULK_STATUS_RESERVATION: '/keisai/bulk-status-reservation',
    CAMPAIGN: '/keisai/campaign',
    CAMPAIGN_DETAIL: '/keisai/campaign-detail',
    CAMPAIGN_MODIFY: '/keisai/campaign-modify',
    CAMPAIGN_MODIFY_CONFIRM: '/keisai/campaign-modify-confirm',
    CAMPAIGN_RICH: '/keisai/campaign-rich',
    CAMPAIGN_OEM: '/keisai/campaign-oem',
    CAMPAIGN_OEM_CONFIRM: '/keisai/campaign-oem-confirm',
    CONTRACT_RESERVATION: '/keisai/contract-reservation',
    FORCE_WINNING: '/keisai/force-winning',
    FORCE_UNSEATED: '/keisai/force-unseated',
    MAIL: '/keisai/mail',
    MAIL_TEMPLATE: '/keisai/mail-template',
    MAIL_TEMPLATE_MODIFY: '/keisai/mail-template-modify',
    DIRECTMAIL_MODIFY: '/keisai/direct-mail-modify',
    DIRECTMAIL_DETAIL: '/keisai/direct-mail-detail',
    F3MAIL_MODIFY: '/keisai/f3-mail-modify',
    F3MAIL_DETAIL: '/keisai/f3-mail-detail',
    F4MAIL_MODIFY: '/keisai/f4-mail-modify',
    F4MAIL_DETAIL: '/keisai/f4-mail-detail',
    F5MAIL_MODIFY: '/keisai/f5-mail-modify',
    F5MAIL_DETAIL: '/keisai/f5-mail-detail',
    MONITOR_RULE_SET: '/keisai/monitor-rule-set',
    MONITOR_RULE_SET_MODIFY: '/keisai/monitor-rule-set-modify',
    MONITOR_RULE_SET_DETAIL: '/keisai/monitor-rule-set-detail',
    MONITOR_BASE_MODIFY: '/keisai/monitor-base-modify',
    MONITOR_MODIFY: '/keisai/monitor-modify',
    MONITOR_LIST: '/keisai/monitor-list',
    MONITOR_APPLY_HISTORY_LIST: '/keisai/monitor-apply-history-list',
    MONITOR_APICLIENTS: '/keisai/monitor/apiclients',
    MANAGEMENT_ANNOUNCE: '/keisai/management-announce',
    MANAGEMENT_ANNOUNCE_MODIFY: '/keisai/management-announce-modify',
    MANAGEMENT_ANNOUNCE_MODIFY_CONFIRM: '/keisai/management-announce-modify-confirm',
    ANNOUNCE: '/keisai/announce',
    ANNOUNCE_MODIFY: '/keisai/announce-modify',
    ANNOUNCE_MODIFY_CONFIRM: '/keisai/announce-modify-confirm',
    BANNER_LIST: '/keisai/banner-list',
    BANNER_ITEM_MODIFY: '/keisai/banner-item-modify',
    BANNER_ITEM_MODIFY_CONFIRM: '/keisai/banner-item-modify-confirm',
    BANNER_INSITE: '/keisai/banner-insite',
    BANNER_PRIORITY_MODIFY: '/keisai/banner-priority-modify',
    BANNER_COMMON: '/keisai/banner-common',
    HOLIDAY_MANAGE: '/keisai/holiday-manage',
    ENQUETE: '/keisai/enquete',
    ENQUETE_MODIFY: '/keisai/enquete-modify',
    POSTING_REQUEST_LIST: '/keisai/posting-request-list',
    POSTING_REQUEST: '/keisai/posting-request',
    POSTING_RESERVATION_LIST: '/keisai/posting-reservation-list',
    POSTING_RESERVATION: '/keisai/posting-reservation',
    CLIENT_ACCOUNT_SETTING: '/keisai/client-account-setting',
    CLIENT_ORGANIZATION_SETTING: '/keisai/client-organization-setting',
    CLIENT: '/keisai/client',
    CLIENT_DETAIL: '/keisai/client-detail',
    PRIOR_ENQUETE_FILE: '/keisai/prior-enquete-file',
    POST_ENQUETE_FILE: '/keisai/post-enquete-file',
    RECEIPT_FILE: '/keisai/receipt-file',
    ENQUETE_FILE: '/keisai/enquete-file',
    COMMENT: '/keisai/comment',
    OEM_BALANCE: '/keisai/oem-balance',
    OEM_BALANCE_LIST: '/keisai/oem-balance-list',
  },
  TENSAKU: {
    TOP: '/tensaku/top',
    QCTOP: '/qccrew/top',
    DATA_INPUT_WORKER: '/data-input-worker/top',
    // 当選店舗付け替え
    SHOP_REPLACE: '/tensaku/shop-replace',
    // ギフト券設定
    GIFT_TICKET: '/tensaku/gift-ticket',
    // クライアントNG一覧
    CLIENT_NG: '/tensaku/client-ng',
    // クライアントNG一覧
    CLIENT_NG_EDIT: '/tensaku/client-ng/edit',
    // ポイント新規付与
    POINT_GRANT: '/tensaku/point/grant',
    // ポイント履歴(/:id)
    POINT_HISTORY: '/tensaku/point/history',
    // 会員一覧
    CUSTOMER_LIST: '/tensaku/customer-list',
    // なり替わりログイン(/:id)
    AGENT_LOGIN: '/tensaku/agent-login',
    // 会員詳細(/:id)
    CUSTOMER_INFO: '/tensaku/customer-info',
    // 関連会員一覧(/:id)
    RELATED_CUSTOMER: '/tensaku/related-customer/list',
    // 電話番号認証履歴(/:id)
    AUTH_HISTORY: '/tensaku/auth-history/list',
    // メール履歴ROI(/:id)
    MAIL_HISTORY_ROI: '/tensaku/mail-history-roi/list',
    // メール履歴OEM(/:id)
    MAIL_HISTORY_OEM: '/tensaku/mail-history-oem/list',
    // OEM
    OEM_FEE_LIST: '/tensaku/oem-fee/list',
    // 有料課金サービス履歴
    CUSTOMER_TICKET_HISTORY: '/tensaku/customer-ticket/history',
    // ログイン履歴（/:id）
    LOGIN_HISTORY: '/tensaku/login-history',
    // 有料サービスポイント返還履歴
    // PAID_SERVICE_POINT_RETURN_HISTORY: '/tensaku/paid-service-point-return-history',
    // 有料サービスポイント返還登録
    // PAID_SERVICE_POINT_RETURN_REGISTER: '/tensaku/paid-service-point-return-register',
    // 一括モニター無効
    BULK_REJECT: '/tensaku/bulk-reject',
    // 期限延長・当選復帰履歴
    EXTEND_DEADLINE_HISTORY: '/tensaku/extend-deadline-history',
    // 期限延長・当選復帰登録
    EXTEND_DEADLINE_REGISTER: '/tensaku/extend-deadline-register',
    // メール一括配信履歴一覧
    MAIL_BULK_DELIVERY_LIST: '/tensaku/mail-bulk-delivery-list',
    // メール一括配信履歴詳細
    MAIL_BULK_DELIVERY_DETAIL: '/tensaku/mail-bulk-delivery-detail',
    // QCクルー案件検索
    QC_CREW_APPLY_SEARCH: '/tensaku/correct/apply-search/qc-crew',
    // 事務局案件検索
    EXECUTIVE_STAFF_APPLY_SEARCH: '/tensaku/correct/apply-search/executive-staff',
    // QCクルー案件管理添削状況サマリー
    QC_CREW_APPLY_MANAGEMENT: '/tensaku/correct/apply-management/qc-crew',
    // 事務局案件管理添削状況サマリー
    EXECUTIVE_STAFF_APPLY_MANAGEMENT: '/tensaku/correct/apply-management/executive-staff',
    // レシート添削
    CORRECT_RECEIPT: '/tensaku/correct/receipt',
    // アンケート添削
    CORRECT_ENQUETE: '/tensaku/correct/enquete',
    // アンケート回答履歴一覧
    ANSWER_HISTORY_LIST: '/tensaku/correct/answer-history-list',
    // 案件分配
    CORRECT_DISTRIBUTE: '/tensaku/correct/normal',
    // データ入力の案件分配
    DATA_INPUT_DISTRIBUTE: '/tensaku/data-iput/distribute',
    // データ入力Wチェックの案件分配
    DATA_INPUT_W_CHECK_DISTRIBUTE: '/tensaku/data-wcheck/distribute',
    // アンケート再提出
    RESUBMIT_ENQUETE: '/tensaku/correct/resubmit-enquete',
    // 納品書添削
    CORRECT_DELIVERY_SLIP: '/tensaku/correct/delivery-slip',
    // データ入力（/:applyId）
    CORRECT_DATA_INPUT: '/tensaku/correct/data-input',
    // データ入力添削（/:applyId）
    CORRECT_DATA_WCHECK: '/tensaku/correct/data-wcheck',
    // 案件分配
    CORRECT_DATA_TARGET_LIST: '/tensaku/correct/target-list',
    // 電話番号マスタ
    CORRECT_PHONE_MASTER: '/tensaku/correct/phone-master',
    // 当選落選履歴(/:id)
    WIN_LOST_HISTORY: '/tensaku/win-lost-history',
    // 会員応募履歴(/:id)
    APPLY_HISTORY_LIST: '/tensaku/apply-history-list',
    // ユーザー情報 応募詳細
    APPLY_INFO: '/tensaku/apply-info',
    // レシート金額修正
    RECEIPT_PRICE_MODIFY: '/tensaku/receipt-price-modify',
    // 調査証明画像表示
    SURVEY_PROOF_IMAGE_LIST: '/tensaku/survey-proof-image/list',
    // 添削用クライアント設定検索
    CORRECT_SETTING_SEARCH: '/tensaku/correct-setting/search',
    // モニター毎添削設定（/:id）
    CORRECT_SETTING_EACH_MONITOR: '/tensaku/correct-setting/each-monitor',
    // クライアント毎添削設定（/:id）
    CORRECT_SETTING_EACH_CLIENT: '/tensaku/correct-setting/each-client',
    // クライアント毎添削設定（/:id）
    OEM_PAGE_LIST: '/tensaku/oem/list',
    // 回答リスト（:applyId）
    ANSWER_LIST: '/tensaku/answer-list',
    // 自動添削再実行待ちバッチ(?p1=:monitorId)
    RE_RUN_AUTO_CORRECT_BATCH: '/api/job/auto-correct-rule',
    // 有料チケット返還
    CUSTOMER_TICKET_RETURN: '/tensaku/customer-ticket/return',
    // 有料チケット付与
    CUSTOMER_TICKET_GRANT: '/tensaku/customer-ticket/grant',
  },
  BILLING: {
    BILLING_PAID_APPLY: '/billing/billing-paid-apply',
    BILLING_LIST: '/billing/billing-list',
    BILLING_DESTINATION: '/billing/billing-destination',
    BILLING_DESTINATION_DETAIL: '/billing/billing-destination-detail',
    BILLING_INVOICE_DETAIL: '/billing/billing-invoice-detail',
    HOLIDAY_MANAGE: '/billing/holiday-manage',
    JOB_HISTORY: '/billing/job-history',
    BILLING_AGENT_LIST: '/billing/billing-agent-list',
    BILLING_AGENT_MONITOR_BASE_LIST: '/billing/billing-agent-monitor-base-list',
    BILLING_AGENT_DETAIL: '/billing/billing-agent-detail',
    BILLING_AGENT_FEE_LIST: '/billing/billing-agent-fee-list',
    BILLING_AGENT_FEE_DETAIL: '/billing/billing-agent-fee-detail',
    BILLING_INVOICE_DEST_RELATION: '/billing/billing-invoice-dest-relation',
    BILLING_FORM_DOWNLOAD: '/billing/billing-form-download',
    DEPOSIT_DOWNLOAD: '/billing/billing-agent-download',
    DEPOSIT_UPLOAD: '/billing/billing-agent-upload',
    YUUCOMI_MANAGER: '/billing/yuucomi-manager',
    YUUCOMI_SUPERIOR: '/billing/yuucomi-superior',
    BLANK: '/billing/blank',
  },
  OEM: {
    TOP: '/oem/top',
    EVENT_MESSAGE: '/oem/event-list',
    MONITOR_LIST: '/oem/monitor-list',
    APPLY_INFO_LIST: '/oem/apply-list',
  },
  TOC_OEM: {
    DETAIL: `${process.env.REACT_APP_TOC_HOST}/detail-oem`,
    PROGRESS: `${process.env.REACT_APP_TOC_HOST}/progress-oem`,
    PRECHECK: `${process.env.REACT_APP_TOC_HOST}/precheck-oem`,
    STORE_GUIDE: `${process.env.REACT_APP_TOC_HOST}/store-guide-oem`,
    ENQ: `${process.env.REACT_APP_TOC_HOST}/enq-oem`,
    RECEIPT: `${process.env.REACT_APP_TOC_HOST}/receipt-oem`,
    BRAND: `${process.env.REACT_APP_TOC_HOST}/brand-oem`,
    CAMPAIGN: `${process.env.REACT_APP_TOC_HOST}/campaign-oem`,
  },
} as const;


export const WhiteList = [
  'inc.oemtest.fancrew.com',
  'toc.oemtest.fancrew.com',
  'tob.oemtest.fancrew.com',
  'images.oemtest.fancrew.com',
  'inc.dev.fancrew.com',
  'toc.dev.fancrew.com',
  'tob.dev.fancrew.com',
  'images.dev.fancrew.com',
  'www.fancrew.jp',
  'r1.fancrew.jp',
  'inc.fancrew.jp',
  'tob.fancrew.jp',
  'images.fancrew.jp',
  's3-ap-northeast-1.amazonaws.com',
  's3-ap-northeast-3.amazonaws.com',
] as const;
