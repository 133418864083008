import { faCopy, faEye, faEyeSlash, faLink, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Button, Form, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { QuestionDeleteApi, QuestionDeleteOutputResponse, QuestionListOutputResponse } from '../../../api-client';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';
import { QuestionInfoPopover } from '../Popover/QuestionInfoPopover';

export interface Props {
  data: QuestionListOutputResponse[];
  setData: Dispatch<SetStateAction<QuestionListOutputResponse[]>>;
  setDeleteRunModal: Dispatch<SetStateAction<boolean>>;
  setDeleteInfoModal: Dispatch<SetStateAction<boolean>>;
  setConfirmModalFlg: Dispatch<SetStateAction<boolean>>;
  setQuestionId: Dispatch<SetStateAction<number | undefined>>;
  onCopy: (targetRow: QuestionListOutputResponse, rowList: QuestionListOutputResponse[]) => void;
  listPerPage: QuestionListOutputResponse[];
  enqueteType: number;
  selectType?: 'global' | 'company';
  adminFlg: boolean;
  salesFlg: boolean;
}

export const QuestionListTable: React.VFC<Props> = ({
  data,
  setData,
  setDeleteRunModal,
  setDeleteInfoModal,
  setConfirmModalFlg,
  setQuestionId,
  onCopy,
  listPerPage,
  enqueteType,
  selectType,
  adminFlg,
  salesFlg,
}) => {
  const copyTooltip = (
    <Tooltip id="button-tooltip" style={{ maxWidth: '25rem' }}>
      Copied!
    </Tooltip>
  );
  const questionPopover = (
    <Popover id="popover-basic" style={{ maxWidth: '25rem' }}>
      チェックした設問を検索結果に
      <br />
      含めないことで論理削除する。
    </Popover>
  );

  const overLayButton = (row: QuestionListOutputResponse, disabled: boolean): JSX.Element => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover style={{ maxWidth: '25rem' }}>
            <QuestionInfoPopover questionId={row.questionId} enqueteType={enqueteType} />
          </Popover>
        }
      >
        <Button
          data-testid="questionContentButton"
          className="ps-0 pt-0"
          variant="link"
          onClick={() => {
            setConfirmModalFlg(true);
            setQuestionId(row.questionId);
          }}
          disabled={disabled}
        >
          <p className="mb-0" style={{ borderBottom: 'solid' }}>
            {row.questionContent}
          </p>
        </Button>
      </OverlayTrigger>
    );
  };

  const deleteQuestionId = (row: QuestionListOutputResponse) => {
    const api = new QuestionDeleteApi();
    api
      .questionDelete({ confirmOnlyFlg: true, questionId: row.questionId })
      .then((res: AxiosResponse<QuestionDeleteOutputResponse>) => {
        if (res.data.usingEnqueteFlg) {
          setDeleteInfoModal(true);
        } else {
          setQuestionId(row.questionId);
          setDeleteRunModal(true);
        }
      });
  };

  // 【事前アンケート編集】
  // 【積上式アンケート編集】
  // 【その他アンケート編集】
  // 【アンケートモニターアンケート編集】
  // からの遷移の場合のPageContents
  const tableContents1 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>設問ID</th>
          <th>設問/回答方法</th>
          {enqueteType === ENQUETE_TYPE.PILEUP && <th style={{ width: '20%' }}>使用クライアント名</th>}
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: QuestionListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.questionId}</td>
              <td className="text-secondary">
                {overLayButton(row, false)}
                <br />
                {row?.questionAnswerMethodName}
              </td>
              {enqueteType === ENQUETE_TYPE.PILEUP && <td>{row?.clientName}</td>}
              {!salesFlg && (
                <td style={{ textAlign: 'center' }}>
                  <OverlayTrigger trigger="click" placement="top-end" overlay={copyTooltip} rootClose>
                    <Button
                      data-testid="copy"
                      variant="link"
                      className="text-secondary"
                      onClick={() => {
                        onCopy(row, data);
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                    </Button>
                  </OverlayTrigger>
                  {/* <Button variant="link" className="text-secondary" onClick={() => deleteQuestionId(row)}>
                  <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
                </Button> */}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 【選択式アンケート編集】
  // からの遷移の場合のPageContents
  const tableContents2 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>設問ID</th>
          <th style={{ textAlign: 'center' }}>カテゴリ</th>
          <th>設問/回答方法/説明</th>
          {selectType === 'company' && (
            <>
              <th style={{ textAlign: 'center' }}>
                使用
                <br />
                クライアント名
              </th>
              <th>&nbsp;</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: QuestionListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.questionId}</td>
              <td style={{ textAlign: 'center' }}>{row?.categoryName}</td>
              <td className="text-secondary">
                {overLayButton(row, false)}
                <br />
                {row?.questionAnswerMethodName}
                {row?.description && (
                  <>
                    <br />
                    {row.description}
                  </>
                )}
              </td>
              {selectType === 'company' && (
                <td width="15%" style={{ textAlign: 'center' }}>
                  {row?.clientName}
                </td>
              )}
              <td width="15%">
                {!salesFlg && (
                  <div style={{ textAlign: 'center' }}>
                    <OverlayTrigger trigger="click" placement="top-end" overlay={copyTooltip} rootClose>
                      <Button
                        data-testid="copy"
                        variant="link"
                        className="text-secondary"
                        onClick={() => {
                          onCopy(row, data);
                        }}
                        disabled={!adminFlg && selectType === 'global'}
                      >
                        <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                      </Button>
                    </OverlayTrigger>
                    {/* {selectType === 'global' && (
                    <Button
                      variant="link"
                      className="text-secondary"
                      onClick={() => deleteQuestionId(row)}
                      disabled={!adminFlg && selectType === 'global'}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
                    </Button>
                  )} */}
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 【会員属性アンケート編集】
  // からの遷移の場合のPageContents
  const tableContents3 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>設問ID</th>
          <th style={{ textAlign: 'center' }}>会員タグ</th>
          <th>設問/回答方法</th>
          {/* <th>&nbsp;</th> */}
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: QuestionListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.questionId}</td>
              <td style={{ textAlign: 'center' }}>{row?.customerTagName}</td>
              <td className="text-secondary">
                {overLayButton(row, false)}
                <br />
                {row?.questionAnswerMethodName}
              </td>
              {/* <td width="15%" style={{ textAlign: 'center' }}>
                <Button variant="link" className="text-secondary" onClick={() => deleteQuestionId(row)}>
                  <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
                </Button>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </>
  );

  return (
    <>
      <Table id="targetTable">
        {enqueteType === ENQUETE_TYPE.BEFOREHAND && tableContents1}
        {enqueteType === ENQUETE_TYPE.SELECT && tableContents2}
        {enqueteType === ENQUETE_TYPE.PILEUP && tableContents1}
        {enqueteType === ENQUETE_TYPE.OTHER && tableContents1}
        {enqueteType === ENQUETE_TYPE.MEMBERSHIP && tableContents3}
        {enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR && tableContents1}
      </Table>
    </>
  );
};
