import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import {
  BillingApi,
  BillingDestinationDetailOutputResponse,
  BillingDestinationMailUpdateFormResponse,
  BillingDestinationUpdateFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';

import {
  BILLING_DEFAULT_FADE_TIME,
  BILLING_MAIL_TYPE,
  FEE_PAYMENT_CONDITION_SELECTION,
  UNSAVED_ID,
} from '../../../BillingConstants';
import { BillingDestinationMailTable } from '../../organisms/Table/BillingDestinationMailTable';
import { BillingUpdateResultModal } from '../../organisms/Modal/BillingUpdateResultModal';
import { QuestionCircleTooltip } from '../../molecules/QuestionCircleTooltip';

export const BillingDestinationDetailPage = () => {
  const [validated, setValidated] = useState(false);
  const { id } = useParams<{ id: string | undefined }>();
  const billingDestinationId = id ? Number(id) : 0;

  const [itemDetail, setItemDetail] = useState<BillingDestinationDetailOutputResponse>({
    accountTransferCode: '',
    accountCode: '',
    address: '',
    aspfeePaymentCondition: 0,
    autoApprovalFlg: false,
    billingPublishmentType: 0,
    billingPublishmentTypeList: [],
    billingStaff: '',
    branchNumber: 0,
    building: '',
    city: '',
    clientId: 0,
    companyName: '',
    department: '',
    disableFlg: false,
    displayWebFlg: false,
    id: 0,
    imagefeePaymentCondition: 0,
    infomartCode: '',
    mailAddressList: [],
    mailPublishmentType: 0,
    mailPublishmentTypeList: [],
    mailSubject: '',
    name: '',
    paymentCondition: [],
    paymentConditionId: 0,
    postalcode: '',
    prefecture: [],
    prefectureId: 0,
    publishBillingFlg: false,
    transferFee: 0,
    billingDeadLine: 0,
    billingableDate: 0,
    paymentTermId: 1,
    paymentTerm: [],
    stylistNominationOptionCondition: 0,
    winnerSegmentOptionCondition: 0,
  });

  const fetchItemDetail = (bilingDestId: number) => {
    billingApi
      .billingDestinationDetail(bilingDestId)
      .then((res: AxiosResponse<BillingDestinationDetailOutputResponse>) => {
        initMailList(res.data);
        setItemDetail((prev) => res.data);
      });
  };

  const initMailList = (response: BillingDestinationDetailOutputResponse) => {
    if (response.mailAddressList === undefined) {
      response.mailAddressList = [];
    }
    for (let i = 0; i < 10; i += 1) {
      if (i in response.mailAddressList) {
        // Do Nothing
      } else {
        response.mailAddressList = [
          ...response.mailAddressList,
          {
            billingDestinationId: itemDetail.id,
            disableFlg: false,
            id: UNSAVED_ID,
            mailAddress: '',
            mailTypeCode: 0,
            bounceMailFlg: false,
            bounceMailFlgUpdateAt: undefined,
            bounceMailReason: undefined,
          },
        ];
      }
    }
  };

  useEffect(() => {
    fetchItemDetail(billingDestinationId);
  }, []);

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  const billingApi = new BillingApi();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // BillingDestinationUpdateFormResponse
    let updMailList: BillingDestinationMailUpdateFormResponse[] = [];
    itemDetail.mailAddressList?.forEach((value) => {
      if (value.id !== UNSAVED_ID || value.mailAddress.trim().length > 0) {
        updMailList = [
          ...updMailList,
          {
            disableFlg: value.disableFlg,
            id: value.id,
            mailAddress: value.mailAddress,
            mailType: value.mailTypeCode,
            bounceMailFlg: value.bounceMailFlg,
            bounceMailFlgUpdateAt: value.bounceMailFlgUpdateAt,
            bounceMailReason: value.bounceMailReason,
          } as BillingDestinationMailUpdateFormResponse,
        ];
      }
    });
    const enableToAddresses = updMailList.filter((v) => !v.bounceMailFlg && v.mailType === BILLING_MAIL_TYPE.TO);
    const enableAddresses = updMailList.filter((v) => !v.bounceMailFlg);
    if (enableAddresses.length > 0 && enableToAddresses.length === 0) {
      setUpdResult({
        result: false,
        errorMessage: 'メールアドレスを登録する場合、TOを1件以上設定する必要があります。',
      });
      return false;
    }

    billingApi
      .billingDestinationUpdate({
        accountTransferCode: itemDetail.accountTransferCode,
        address: itemDetail.address,
        aspfeePaymentCondition: itemDetail.aspfeePaymentCondition,
        autoApprovalFlg: itemDetail.autoApprovalFlg,
        billingPublishmentType: itemDetail.billingPublishmentType,
        billingStaff: itemDetail.billingStaff,
        branchNumber: itemDetail.branchNumber,
        building: itemDetail.building,
        city: itemDetail.city,
        clientId: itemDetail.clientId,
        companyName: itemDetail.companyName,
        department: itemDetail.department,
        disableFlg: itemDetail.disableFlg,
        displayWebFlg: itemDetail.displayWebFlg,
        id: itemDetail.id,
        imagefeePaymentCondition: itemDetail.imagefeePaymentCondition,
        infomartCode: itemDetail.infomartCode,
        mailAddressList: updMailList,
        mailPublishmentType: itemDetail.mailPublishmentType,
        mailSubject: itemDetail.mailSubject,
        name: itemDetail.name,
        paymentConditionId: itemDetail.paymentConditionId,
        postalcode: itemDetail.postalcode,
        prefectureId: itemDetail.prefectureId,
        publishBillingFlg: itemDetail.publishBillingFlg,
        transferFee: itemDetail.transferFee,
        billingDeadLine: itemDetail.billingDeadLine,
        billingableDate: itemDetail.billingableDate,
        paymentTermId: itemDetail.paymentTermId,
        accountCode: itemDetail.accountCode,
        stylistNominationOptionCondition: itemDetail.stylistNominationOptionCondition,
        winnerSegmentOptionCondition: itemDetail.winnerSegmentOptionCondition,
      } as BillingDestinationUpdateFormResponse)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResult(res.data);
      })
      .catch((error) => {
        setUpdResult({ result: false });
      });
  };

  const handleSearchPrefecture = () => {
    const regex = /\d{7}/g;
    if (itemDetail.postalcode !== undefined && regex.test(itemDetail.postalcode)) {
      billingApi.prefecturesByPostalcode(itemDetail.postalcode).then((res) => {
        setItemDetail((prevState) => ({
          ...prevState,
          ...{
            prefectureId: res.data.prefecturesId,
            city: res.data.city,
          },
        }));
      });
    }
  };

  const handleMailChange = (index: number, value: string) => {
    const newAddresses = itemDetail.mailAddressList?.map((v, i) => {
      if (index === i) {
        return {
          ...v,
          mailAddress: value,
        };
      }
      return v;
    });
    setItemDetail((prevState) => ({ ...prevState, mailAddressList: newAddresses }));
  };

  const handleMailTypeChange = (index: number, value: number) => {
    const newAddresses = itemDetail.mailAddressList?.map((v, i) => {
      if (index === i) {
        return {
          ...v,
          mailTypeCode: value,
        };
      }
      return v;
    });
    setItemDetail((prevState) => ({ ...prevState, mailAddressList: newAddresses }));
  };

  const handleMailStatusChange = (index: number, value: boolean) => {
    const newAddresses = itemDetail.mailAddressList?.map((v, i) => {
      if (index === i) {
        return {
          ...v,
          bounceMailFlg: value,
        };
      }
      return v;
    });
    setItemDetail((prevState) => ({ ...prevState, mailAddressList: newAddresses }));
  };

  const handleMailDisableFlgChange = (index: number, value: boolean) => {
    const newAddresses = itemDetail.mailAddressList?.map((v, i) => {
      if (index === i) {
        return {
          ...v,
          disableFlg: value,
        };
      }
      return v;
    });
    setItemDetail((prevState) => ({ ...prevState, mailAddressList: newAddresses }));
  };

  return (
    <>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="name">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">案件名</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.name ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="branchNumber">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">枝番</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control type="text" value={itemDetail.branchNumber ?? 1} readOnly />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="companyName">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">送付先社名</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.companyName ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        companyName: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="department">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">部署・役職</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.department ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        department: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="billingStaff">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">送付先担当者名(様不要)</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.billingStaff ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        billingStaff: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="postalcode">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">郵便番号</Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    type="number"
                    maxLength={7}
                    value={itemDetail.postalcode ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        postalcode: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col className="col-3">
                  <Button variant="secondary" onClick={() => handleSearchPrefecture()}>
                    検索
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="prefectureId">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">都道府県</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.prefectureId ?? 1}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        prefectureId: Number(e.target.value),
                      }))
                    }
                  >
                    {itemDetail.prefecture.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="city">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">市区町村</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.city ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        city: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="address">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">番地・町域</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.address ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        address: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="building">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">ビル名</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.building ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        building: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="aspfeePaymentCondition">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">ASP利用料発生条件</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.aspfeePaymentCondition}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        aspfeePaymentCondition: Number(e.target.value),
                      }))
                    }
                  >
                    {FEE_PAYMENT_CONDITION_SELECTION.map((v) => (
                      <option value={v.value} key={v.value}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="imagefeePaymentCondition">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">画像フィー発生条件</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.imagefeePaymentCondition}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        imagefeePaymentCondition: Number(e.target.value),
                      }))
                    }
                  >
                    {FEE_PAYMENT_CONDITION_SELECTION.map((v) => (
                      <option value={v.value} key={v.value}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-6">
            <Form.Group controlId="stylistNominationOptionCondition">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">月間スタイリスト指名オプション</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.stylistNominationOptionCondition}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        stylistNominationOptionCondition: Number(e.target.value),
                      }))
                    }
                  >
                    {FEE_PAYMENT_CONDITION_SELECTION.map((v) => (
                      <option value={v.value}>{v.name}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="winnerSegmentOptionCondition">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">月額当選者セグメントオプション</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.winnerSegmentOptionCondition}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        winnerSegmentOptionCondition: Number(e.target.value),
                      }))
                    }
                  >
                    {FEE_PAYMENT_CONDITION_SELECTION.map((v) => (
                      <option value={v.value}>{v.name}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-6">
            <Form.Group controlId="billingPublishmentType">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">紙発行帳票</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.billingPublishmentType ?? 2}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        billingPublishmentType: Number(e.target.value),
                      }))
                    }
                  >
                    {itemDetail.billingPublishmentTypeList.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="paymentConditionId">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">支払い条件</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.paymentConditionId ?? 1}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        paymentConditionId: Number(e.target.value),
                      }))
                    }
                  >
                    {itemDetail.paymentCondition.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="autoApprovalFlg">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">自動承認</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.autoApprovalFlg ? 1 : 0}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        autoApprovalFlg: Boolean(Number(e.target.value)),
                      }))
                    }
                  >
                    <option value={1}>する</option>
                    <option value={0}>しない</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="transferFee">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">口座振替手数料</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="number"
                    value={itemDetail.transferFee ?? 0}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        transferFee: Number(e.target.value),
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="accountTransferCode">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">口座振替企業コード</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.accountTransferCode ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        accountTransferCode: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="accountCode">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">振替口座情報</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.accountCode ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        accountCode: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group controlId="publishBillingFlg">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">流し込み作成</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.publishBillingFlg ? 1 : 0}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        publishBillingFlg: Boolean(Number(e.target.value)),
                      }))
                    }
                  >
                    <option value={0}>する</option>
                    <option value={1}>しない</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="displayWebFlg">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">Web発行</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.displayWebFlg ? 1 : 0}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        displayWebFlg: Boolean(Number(e.target.value)),
                      }))
                    }
                  >
                    <option value={1}>する</option>
                    <option value={0}>しない</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-6">
            <Form.Group controlId="billingDeadLine">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">請求締め日</Form.Label>
                  <QuestionCircleTooltip tooltip="0が設定されていると完了月に請求書が発行されます。1が設定されると1日完了分が前月分請求書に含まれるようになります。" />
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="number"
                    value={itemDetail.billingDeadLine}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        billingDeadLine: Number(e.target.value),
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="billingableDate">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">請求可能期限</Form.Label>
                  <QuestionCircleTooltip tooltip="0が設定されている場合非請求になることはありません。1を設定すると翌1日完了分から非請求になります。" />
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="number"
                    value={itemDetail.billingableDate}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        billingableDate: Number(e.target.value),
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-6">
            <Form.Group controlId="paymentTermId">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">入金サイト</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.paymentTermId}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        paymentTermId: Number(e.target.value),
                      }))
                    }
                  >
                    {itemDetail.paymentTerm.map((v) => {
                      return (
                        <option key={v.id} value={v.id}>
                          {v.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="infomartCode">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">インフォマート請求コード</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.infomartCode ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        infomartCode: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-6">
            <Form.Group controlId="mailPublishmentType">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">メール発行帳票</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    value={itemDetail.mailPublishmentType ?? 1}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        mailPublishmentType: Number(e.target.value),
                      }))
                    }
                  >
                    {itemDetail.mailPublishmentTypeList.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="mailSubject">
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">メール件名</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control
                    type="text"
                    value={itemDetail.mailSubject ?? ''}
                    onChange={(e) =>
                      setItemDetail((prevState) => ({
                        ...prevState,
                        mailSubject: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 mt-1">
            <Form.Group controlId="mailAddress">
              <Row>
                <Col className="col-12">
                  <Form.Label className="mt-2 me-2">メールアドレス</Form.Label>
                </Col>
              </Row>
              <BillingDestinationMailTable
                onChangeAddress={handleMailChange}
                onChangeAddressType={handleMailTypeChange}
                onChangeAddressStatus={handleMailStatusChange}
                onChangeAddressDisableFlg={handleMailDisableFlgChange}
                mailList={itemDetail.mailAddressList}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center">
            <Button type="submit" variant="secondary" className="m-2">
              更新
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};
