import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import { IncResultOutputResponse } from '../../../api-client';

export interface Props {
  fadeoutTime?: number;
  setUpdResult: Dispatch<SetStateAction<IncResultOutputResponse | undefined>>;
  updResult?: IncResultOutputResponse;
}

export const BillingUpdateResultModal: React.VFC<Props> = ({ fadeoutTime, setUpdResult, updResult }) => {
  const [isModal, setIsModal] = useState(true);
  const onHide = () => {
    setIsModal(false);
  };

  let message = '';

  if (updResult === undefined) {
    // do nothing
  } else if (updResult.result) {
    message = '更新に成功しました。';
  } else {
    message = '更新に失敗しました。';
  }

  useEffect(() => {
    if (fadeoutTime !== null && fadeoutTime !== undefined) {
      const timer = setTimeout(() => {
        setIsModal(false);
        setUpdResult(undefined);
      }, fadeoutTime);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <Form>
            <Container className="text-vertical-align-middle text-center">{message}</Container>
            {updResult?.errorMessage && (
              <Container className="text-vertical-align-middle text-center small">{updResult.errorMessage}</Container>
            )}
          </Form>
        }
      />
    </>
  );
};
