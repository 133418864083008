/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';
import { faPen, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Form, Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';
import { Title } from '../../atoms/Title';
import { Url } from '../../../constants/Url';
import { useDebounce } from '../../../hooks/useDebounce';
import { EnqueteQuestionListTable } from '../../organisms/Table/EnqueteQuestionListTable';
import { MonitorPostEnqueteModal } from '../../organisms/Modal/MonitorPostEnqueteModal';
import {
  AllClientResponse,
  ClientListApi,
  ClientListOutputResponse,
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  CustomerAttributeEnqueteCreateApi,
  EnqueteInfoApi,
  EnqueteInfoOutputResponse,
  EnqueteNameUniqueCheckApi,
  EnqueteQuestionBranchDataResponse,
  EnqueteQuestionBranchOutputResponse,
  EnqueteQuestionsDataResponse,
  EnqueteQuestionSettingOutputResponse,
  IncResultOutputResponse,
  MonitorEnqueteUpsertApi,
  OtherEnqueteUpsertApi,
  PreEnqueteUpsertApi,
  QuestionCategoryListApi,
  QuestionCategoryListOutputResponse,
  ResearchTypeBusinessTypeListApi,
  BusinessTypeMapOutputResponse,
  RoleListApi,
  SelectionEnqueteUpsertApi,
  StackedEnqueteUpsertApi,
  IdNameOutputResponse,
} from '../../../api-client';
import { PromptReportMailModal } from '../../organisms/Modal/PromptReportMailModal';
import { AllotmentPointConfirmModal } from '../../organisms/Modal/AllotmentPointConfirmModal';
import { JumpQuestionModal } from '../../organisms/Modal/JumpQuestionModal';
import { JumpQuestionSelectModal } from '../../organisms/Modal/JumpQuestionSelectModal';
import { EnqueteCategoryConfirmModal } from '../../organisms/Modal/EnqueteCategoryConfirmModal';
import { QuestionListModal } from '../../organisms/Modal/QuestionListModal';
import { QuestionConfirmModal } from '../../organisms/Modal/Question/QuestionConfirmModal';
import { ENQUETE_TYPE } from './EnqueteListPage';
import { when } from '../../../utils/functions';

export interface QuestionListState {
  enqueteId?: string;
  referrer?: string;
  categoryList?: Array<number>;
  clientId?: number;
  content?: string;
  id?: number;
}

export const EnqueteDetailModifyPage: React.VFC = () => {
  const infoApi = new EnqueteInfoApi();
  const researchTypeBusinessTypeListApi = new ResearchTypeBusinessTypeListApi();
  const commonApi = new CommonMasterListApi();
  const clientListApi = new ClientListApi();
  const questionCategoryListApi = new QuestionCategoryListApi();
  const preUpdateApi = new PreEnqueteUpsertApi();
  const otherUpdateApi = new OtherEnqueteUpsertApi();
  const customerAttributeUpdateApi = new CustomerAttributeEnqueteCreateApi();
  const monitorUpdateApi = new MonitorEnqueteUpsertApi();
  const selectionUpdateApi = new SelectionEnqueteUpsertApi();
  const stackedUpdateApi = new StackedEnqueteUpsertApi();
  const enqueteNameUniqueCheckApi = new EnqueteNameUniqueCheckApi();
  const roleListApi = new RoleListApi();

  const debounce = useDebounce();

  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState<number>(0);

  const { type, enqueteId } = useParams<{
    type: string | undefined;
    enqueteId: string | undefined;
  }>();

  const [data, setData] = useState<EnqueteInfoOutputResponse>({
    branches: [],
    enqueteName: '',
    enqueteQuestionsList: [],
    enqueteType: Number(type),
    pointSettings: [],
    categoryId: undefined,
    description: undefined,
    clients: [],
    useClients: [],
    globalFlg: false,
    researchTypeId: undefined,
    businessTypeId: undefined,
  });

  const history = useHistory();

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  const [reportMailModalFlg, setReportMailModalFlg] = useState<boolean>(false);
  const [allotmentPointCofirmModalFlg, setAllotmentPointCofirmModalFlg] = useState<boolean>(false);
  const [deleteModalFlg, setDeleteModalFlg] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<CommonMasterListOutputResponse[]>([]);
  const [questionCategoryList, setQuestionCategoryList] = useState<QuestionCategoryListOutputResponse[]>([]);
  const [researchTypeList, setResearchTypeList] = useState<CommonMasterListOutputResponse[]>([]);
  const [businessTypeMap, setBusinessTypeMap] = useState<BusinessTypeMapOutputResponse>();
  const [clientList, setClientList] = useState<AllClientResponse[]>([]);
  const [deleteQuestionList, setDeleteQuestionList] = useState<EnqueteQuestionsDataResponse[]>([]);
  const [jumpQuestionModalFlg, setJumpQuestionModalFlg] = useState<boolean>(false);
  const [branchList, setBranchList] = useState<any[] | undefined>([]);
  const [selectedBranch, setSelectedBranch] = useState<EnqueteQuestionBranchOutputResponse | undefined>();
  const [jumpQuestionSelectedModalFlg, setJumpQuestionSelectedModalFlg] = useState<boolean>(false);
  const [categoryConfirmModalFlg, setCategoryConfirmModalFlg] = useState<boolean>(false);
  const [confirmFlg, setConfirmFlg] = useState<boolean>(false);
  const [questionListModalFlg, setQuestionListModalFlg] = useState<boolean>(false);
  const [researchTypeNotSelectedFlg, setResearchTypeNotSelectedFlg] = useState<boolean>(false);
  const [isOriginalEnqueteModal, setIsOriginalEnqueteModal] = useState<boolean>(false);

  const [selectedQuestionId, setSelectedQuestionId] = useState<number>();

  const [originQuestion, setOriginQuestion] = useState<{
    enqueteQuestionsId: number;
    questionId: number;
    mailContent: string | undefined;
    deleteFlg: boolean;
    questionCategoryId: number | undefined;
  }>();
  const [isQuestionConfirmModal, setIsQuestionConfirmModal] = useState<boolean>(false);
  const [defaultEnqueteName, setDefaultEnqueteName] = useState<string>();
  const [isInvalidEnqueteName, setIsInvalidEnqueteName] = useState<boolean>(false);
  const [categoryListFlg, setCategoryListFlg] = useState<boolean>(false);
  const [adminFlg, setAdminFlg] = useState<boolean>(false);

  // 画面初期表示時に一回だけロール一覧を取得し、グローバル設問を追加・更新・削除できる権限を持っているか確認する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setAdminFlg(res.data.some((role) => role.name === '設問管理者'));
    });
  }, []);

  useEffect(() => {
    if (type == null) {
      history.push(Url.COMMON_ERROR);
      return;
    }

    if (enqueteId) {
      infoApi.enqueteInfo(Number(enqueteId)).then((res: AxiosResponse<EnqueteInfoOutputResponse>) => {
        setData(res.data);
        setDefaultEnqueteName(res.data.enqueteName);
      });
    }

    clientListApi.clientList().then((res: AxiosResponse<ClientListOutputResponse>) => {
      setClientList(res.data.allClientList);
    });

    if (Number(type) === ENQUETE_TYPE.SELECT) {
      commonApi.commonMasterList('category').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
        setCategoryList(res.data);
      });
      commonApi.commonMasterList('research_type').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
        setResearchTypeList(res.data);
      });
      researchTypeBusinessTypeListApi
        .researchTypeBusinessTypeList()
        .then((res: AxiosResponse<BusinessTypeMapOutputResponse>) => {
          setBusinessTypeMap(res.data);
        });
    }
  }, [type, enqueteId]);

  useEffect(() => {
    if (Number(type) === ENQUETE_TYPE.PILEUP || Number(type) === ENQUETE_TYPE.SELECT) {
      if (data.clients) {
        questionCategoryListApi
          .questionCategoryList(
            data.clients.map((_) => _.id),
            true
          )
          .then((res: AxiosResponse<QuestionCategoryListOutputResponse[]>) => {
            setQuestionCategoryList(res.data);
            setCategoryListFlg(true);
          });
      }
    } else {
      setCategoryListFlg(true);
    }
  }, [data.clients]);

  useEffect(() => {
    debounce(() => {
      if (!data?.enqueteName) return;
      if (defaultEnqueteName === data?.enqueteName) {
        setIsInvalidEnqueteName(false);
        return;
      }
      enqueteNameUniqueCheckApi
        .enqueteNameUniqueCheck(data?.enqueteName)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          setIsInvalidEnqueteName(!res.data.result);
        });
    });
  }, [debounce, data?.enqueteName, defaultEnqueteName]);

  // ドラッグアンドドロップイベント
  const handleOnDragEndItem = (result: any) => {
    if (result.destination === null || data === undefined) return;

    const items: EnqueteQuestionSettingOutputResponse[] = Array.from(data?.enqueteQuestionsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData({ ...data, enqueteQuestionsList: items });
  };

  // 保存ボタンイベント
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // UpdateParamセット---------------------------------------------------------------------
    const branches: EnqueteQuestionBranchDataResponse[] = [];
    let branchSelectionIdList: number[] = [];
    const enqueteQuestionList: EnqueteQuestionsDataResponse[] = [];

    // 条件分岐情報を詰める
    for (let index = 0; index < data.branches.length; index += 1) {
      // 条件分岐情報が持つ選択肢情報を詰める
      for (let ind = 0; ind < data.branches[index].selections.length; ind += 1) {
        if (data.branches[index].selections[ind].selectFlg) {
          branchSelectionIdList.push(data.branches[index].selections[ind].selectionId);
        }
      }
      branches.push({
        branchId: data.branches[index].branchId === 0 ? undefined : data.branches[index].branchId,
        targetQuestionId: data.branches[index].targetQuestionId,
        conditionQuestionId: data.branches[index].conditionQuestionId,
        condition: data.branches[index].condition,
        conditionSelections: branchSelectionIdList,
        disableFlg: data.branches[index].disableFlg,
      });
      branchSelectionIdList = [];
    }

    // 設問一覧情報を詰める
    for (let index = 0; index < data.enqueteQuestionsList.length; index += 1) {
      enqueteQuestionList.push({
        // 新規追加したものであればNull
        id:
          data.enqueteQuestionsList[index].enqueteQuestionsId === 0
            ? undefined
            : data.enqueteQuestionsList[index].enqueteQuestionsId,
        questionId: data.enqueteQuestionsList[index].questionId,
        deleteFlg: false,
        mailContent:
          data.enqueteQuestionsList[index].mailContent === null
            ? undefined
            : data.enqueteQuestionsList[index].mailContent,
        questionCategoryId: data.enqueteQuestionsList[index].questionCategoryId,
      });
    }
    // 削除設問情報を詰める
    for (let index = 0; index < deleteQuestionList.length; index += 1) {
      enqueteQuestionList.push({
        id: deleteQuestionList[index].id,
        questionId: deleteQuestionList[index].questionId,
        deleteFlg: true,
        mailContent: deleteQuestionList[index].mailContent === null ? undefined : deleteQuestionList[index].mailContent,
        questionCategoryId: deleteQuestionList[index].questionCategoryId,
      });
    }

    const param: any = {
      enqueteId: Number(enqueteId),
      enqueteName: data.enqueteName,
      enqueteDisplayName: data.enqueteDisplayName || undefined,
      description: data.description || undefined,
      globalFlg: data.globalFlg,
      clients: data.clients.map((_) => _.id),
      branches,
      enqueteQuestionsList: enqueteQuestionList,
      researchTypeId: data.researchTypeId,
      businessTypeId: data.businessTypeId,
      originalEnqueteId: data.originalEnqueteId,
    };

    switch (Number(type)) {
      case ENQUETE_TYPE.BEFOREHAND: {
        preUpdateApi.preEnqueteUpsert(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
          afterUpsert(res.data);
        });
        break;
      }
      case ENQUETE_TYPE.SELECT: {
        selectionUpdateApi.selectionEnqueteUpsert(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
          afterUpsert(res.data);
        });
        break;
      }
      case ENQUETE_TYPE.PILEUP: {
        param.pointSettings = data.pointSettings;
        stackedUpdateApi.stackedEnqueteUpsert(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
          afterUpsert(res.data);
        });
        break;
      }
      case ENQUETE_TYPE.OTHER: {
        otherUpdateApi.otherEnqueteUpsert(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
          afterUpsert(res.data);
        });
        break;
      }
      case ENQUETE_TYPE.MEMBERSHIP: {
        customerAttributeUpdateApi
          .customerAttributeUpsertUpsert(param)
          .then((res: AxiosResponse<IncResultOutputResponse>) => {
            afterUpsert(res.data);
          });
        break;
      }
      case ENQUETE_TYPE.ENQUETE_MONITOR: {
        monitorUpdateApi.monitorEnqueteUpsert(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
          afterUpsert(res.data);
        });
        break;
      }
      default:
        history.push(Url.COMMON_ERROR);
        break;
    }
  };

  const afterUpsert = (response: IncResultOutputResponse) => {
    // 画面再描画
    if (enqueteId === undefined) {
      // 新規
      history.push(`./${type}/${response.id}`);
    } else {
      // 既存更新
      window.location.reload();
    }
    setUpdResult(response);
    setDeleteQuestionList([]);
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    switch (e.target.id) {
      case 'enqueteName':
      case 'enqueteDisplayName':
      case 'description':
        setData({ ...data, [e.target.id]: e.target.value });
        setUpdResult(undefined);
        break;
      case 'questionContext':
        if (data) {
          setData({
            ...data,
            enqueteQuestionsList: data?.enqueteQuestionsList.map((q, i) =>
              i === index ? { ...q, content: e.target.value } : q
            ),
          });
        }
        setUpdResult(undefined);
        break;
      default:
        break;
    }
  };

  const onChangeClientSelect = (selectedContents: MultiValue<{ clientId: number; clientName: string }>) => {
    setData({ ...data, clients: selectedContents.map((c) => ({ id: c.clientId, name: c.clientName })) });
    setUpdResult(undefined);
  };

  const handleClickModalYes = (): void => {
    const newData: EnqueteQuestionSettingOutputResponse[] | undefined = data?.enqueteQuestionsList.filter(
      (item: EnqueteQuestionSettingOutputResponse) => item.questionId !== selectedQuestionId
    );
    if (data?.enqueteQuestionsList !== undefined && newData !== undefined) {
      setData({ ...data, enqueteQuestionsList: newData });
    }
    setUpdResult(undefined);
    setDeleteModalFlg(false);
  };

  const handleClickModalNo = (): void => {
    setDeleteModalFlg(false);
    setSelectedQuestionId(undefined);
  };

  return (
    <>
      {isOriginalEnqueteModal && (
        <MonitorPostEnqueteModal
          isModal={isOriginalEnqueteModal}
          onSave={(id, name, researchTypeId) => {
            setData({ ...data, originalEnqueteId: id, originalEnqueteName: name });
            setIsOriginalEnqueteModal(false);
          }}
          onHide={() => setIsOriginalEnqueteModal(false)}
        />
      )}
      {isQuestionConfirmModal && (
        <QuestionConfirmModal
          isModal={isQuestionConfirmModal}
          setIsModal={setIsQuestionConfirmModal}
          setAnswerMethodModalFlg={setIsQuestionConfirmModal}
          enqueteType={Number(type)}
          selectType="company"
          parentData={data}
          setParentData={setData}
          clientIds={data.clients.map((v) => v.id)}
        />
      )}

      <PromptReportMailModal
        data={data}
        setData={setData}
        showFlg={reportMailModalFlg}
        setShowFlg={setReportMailModalFlg}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
      />
      <AllotmentPointConfirmModal
        data={data}
        setData={setData}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
        showFlg={allotmentPointCofirmModalFlg}
        setShowFlg={setAllotmentPointCofirmModalFlg}
      />
      <JumpQuestionModal
        data={data}
        setData={setData}
        showFlg={jumpQuestionModalFlg}
        branchList={branchList}
        setBranchList={setBranchList}
        setSelectedBranch={setSelectedBranch}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
        setShowFlg={setJumpQuestionModalFlg}
        setJumpQuestionSelectedModalFlg={setJumpQuestionSelectedModalFlg}
      />
      <JumpQuestionSelectModal
        data={data}
        branchList={branchList}
        setBranchList={setBranchList}
        selectedBranch={selectedBranch}
        showFlg={jumpQuestionSelectedModalFlg}
        setShowFlg={setJumpQuestionSelectedModalFlg}
        selectedQuestionId={selectedQuestionId}
        selectedQuestionIdx={selectedQuestionIdx}
      />

      <EnqueteCategoryConfirmModal
        clientIds={data.clients.map((c) => c.id)}
        showFlg={categoryConfirmModalFlg}
        setShowFlg={setCategoryConfirmModalFlg}
        setQuestionCategoryList={setQuestionCategoryList}
      />
      {questionListModalFlg && (
        <QuestionListModal
          data={data}
          setData={setData}
          showFlg={questionListModalFlg}
          setShowFlg={setQuestionListModalFlg}
          enqueteType={Number(type)}
          originQuestion={originQuestion}
          setOriginQuestion={setOriginQuestion}
          setIsQuestionConfirmModal={setIsQuestionConfirmModal}
          deleteQuestionList={deleteQuestionList}
          setDeleteQuestionList={setDeleteQuestionList}
          clientList={clientList}
        />
      )}
      <Modal show={deleteModalFlg} onHide={handleClickModalNo}>
        <Modal.Header style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>削除してよろしいですか？</Modal.Header>
        <Modal.Footer>
          <Button className="me-2" variant="secondary" onClick={handleClickModalNo}>
            いいえ
          </Button>
          <Button variant="primary" onClick={handleClickModalYes}>
            はい
          </Button>
        </Modal.Footer>
      </Modal>
      <Title>アンケート編集</Title>
      {updResult?.result && (
        <Alert variant="success" style={{ marginBottom: '24px' }}>
          保存が完了しました。
        </Alert>
      )}
      {updResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      {researchTypeNotSelectedFlg && (
        <Alert variant="warning" style={{ marginBottom: '24px' }}>
          調査種別を選択してください。
        </Alert>
      )}
      <Form onSubmit={handleOnSubmit}>
        <div className="d-flex mt-4">
          <div className="me-auto text-left" style={{ fontSize: '1.3rem' }}>
            <Badge pill className="text-secondary bg-transparent border border-secondary border-1">
              {['事前', '事後 選択式', '事後 積上式', '会員属性', 'アンケートモニター', '事後 その他'][
                Number(type) - 1
              ] || ''}
            </Badge>
            {data.globalFlg && Number(enqueteId) && (
              <Badge pill className="text-secondary bg-transparent border border-secondary border-1 ms-2">
                グローバルアンケート
              </Badge>
            )}
          </div>
          <div>
            <Button id="keep" type="submit" className="text-center" disabled={isInvalidEnqueteName}>
              保存
            </Button>
          </div>
        </div>
        {Number(enqueteId) === undefined && (
          <div className="d-flex justify-content-end mt-4 fw-bold">
            グローバルアンケート
            <Form.Check data-testid="highLoadModeCheckbox">
              <Form.Check.Input
                data-testid="highLoadModeCheckbox"
                className="ms-2"
                type="checkbox"
                checked={data.globalFlg}
                onChange={() => {
                  setData({ ...data, globalFlg: !data.globalFlg });
                }}
              />
            </Form.Check>
          </div>
        )}

        <Form.Group className={Number(enqueteId) !== undefined ? 'mb-4 mt-4' : 'mb-4'} controlId="enqueteName">
          <Form.Label className="fw-bold">アンケート名</Form.Label>
          <Form.Control
            type="text"
            value={data?.enqueteName || ''}
            onChange={onChangeText}
            isInvalid={isInvalidEnqueteName}
          />
          <Form.Control.Feedback type="invalid">入力されたアンケート名は既に登録されています。</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" controlId="enqueteDisplayName">
          <Form.Label className="fw-bold">表示用アンケート名</Form.Label>
          <Form.Control type="text" value={data?.enqueteDisplayName || ''} onChange={onChangeText} />
        </Form.Group>
        {Number(type) === ENQUETE_TYPE.SELECT && (
          <>
            <Form.Group className="mb-4" controlId="originalEnquete">
              <Form.Label>オリジナルアンケート{!adminFlg && <span className="text-danger">*</span>}</Form.Label>

              <div className="position-relative">
                <Form.Control
                  required={!adminFlg}
                  value={data?.originalEnqueteName || ''}
                  onChange={() => {}}
                  className="bg-white py-3"
                />
                <div className="position-absolute top-50 translate-middle-y" style={{ right: '1rem' }}>
                  {data?.originalEnqueteId && (
                    <Button
                      variant="link"
                      className="text-secondary"
                      onClick={() => {
                        setData({ ...data, originalEnqueteId: undefined, originalEnqueteName: undefined });
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                    </Button>
                  )}
                  <Button className="text-nowrap" onClick={() => setIsOriginalEnqueteModal(true)}>
                    選択
                  </Button>
                </div>
              </div>
            </Form.Group>
          </>
        )}
        <Form.Group className="mb-4 mt-4" controlId="description">
          <Form.Label className="fw-bold">説明</Form.Label>
          <Form.Control as="textarea" rows={3} value={data?.description || ''} onChange={onChangeText} />
        </Form.Group>
        <Form.Group className="mb-4 mt-4" controlId="managementClient">
          <Form.Label className="fw-bold">管理クライアント</Form.Label>
          <Select
            id="client"
            className="mb-4"
            value={data.clients.map((d) => ({ clientId: d.id, clientName: d.name }))}
            isMulti
            onChange={(e) => onChangeClientSelect(e)}
            closeMenuOnSelect={false}
            getOptionLabel={(option: any) => option.clientName}
            getOptionValue={(option: any) => option.clientId}
            options={clientList}
            placeholder="クライアントを設定する。"
          />
        </Form.Group>
        <Form.Group className="mb-4 mt-4" controlId="usedClient">
          <Form.Label className="fw-bold">使用クライアント</Form.Label>
          <Select
            id="useClient"
            className="mb-4"
            value={data.useClients.map((useClient) => ({ clientId: useClient.id, clientName: useClient.name }))}
            isMulti
            getOptionLabel={(option: any) => option.clientName}
            getOptionValue={(option: any) => option.clientId}
            placeholder="未設定"
            isDisabled
          />
        </Form.Group>
        {Number(type) === ENQUETE_TYPE.SELECT && (
          <>
            <Form.Group className="mb-4" controlId="researchTypeId">
              <Form.Label>調査種別</Form.Label>
              <Form.Select
                value={data.researchTypeId ?? ''}
                required
                onChange={(e) => {
                  const value = e.target.value ? Number(e.target.value) : undefined;
                  setData({ ...data, researchTypeId: value });
                  setResearchTypeNotSelectedFlg(false);
                  // setData({ ...data, researchTypeId: Number(e.target.value) });
                }}
              >
                <option value="">&nbsp;</option>
                {researchTypeList?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/*
            <Form.Group className="mb-4" controlId="businessTypeId">
              <Form.Label>業態</Form.Label>
              <Form.Select
                value={data.businessTypeId ?? ''}
                required
                onChange={(e) => {
                  setData({ ...data, businessTypeId: Number(e.target.value) });
                }}
              >
                <option value="">&nbsp;</option>
                {businessTypeMap &&
                  data.researchTypeId &&
                  businessTypeMap?.map[`${data.researchTypeId ? `${data.researchTypeId}` : ''}`]?.map(
                    ({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    )
                  )}
              </Form.Select>
            </Form.Group>
            */}
          </>
        )}
        {Number(type) === ENQUETE_TYPE.PILEUP && (
          <>
            <div className="d-flex">
              <div style={{ fontWeight: 'bold' }}>アンケート内利用カテゴリ</div>
              <div className="mb-4">
                <Button
                  className="pt-0"
                  variant="link"
                  onClick={() => {
                    setCategoryConfirmModalFlg(true);
                    setConfirmFlg(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth className="me-1" />
                  カテゴリ編集
                </Button>
              </div>
            </div>
          </>
        )}
        <Card>
          <Card.Header>
            <h4>設問一覧</h4>
          </Card.Header>
          <Card.Body>
            {when(
              categoryListFlg,
              <EnqueteQuestionListTable
                data={data}
                categoryList={categoryList}
                questionCategoryList={questionCategoryList}
                enqueteType={Number(type)}
                deleteQuestionList={deleteQuestionList}
                setDeleteQuestionList={setDeleteQuestionList}
                setDeleteModalFlg={setDeleteModalFlg}
                handleOnDragEndItem={handleOnDragEndItem}
                setReportMailModalFlg={setReportMailModalFlg}
                setAllotmentPointCofirmModalFlg={setAllotmentPointCofirmModalFlg}
                setQuestionListModalFlg={setQuestionListModalFlg}
                setSelectedQuestionId={setSelectedQuestionId}
                setJumpQuestionModalFlg={setJumpQuestionModalFlg}
                setSelectedQuestionIdx={setSelectedQuestionIdx}
                setOriginQuestion={setOriginQuestion}
                setResearchTypeNotSelectedFlg={setResearchTypeNotSelectedFlg}
              />
            )}
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
